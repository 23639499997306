/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-01-11 11:57:10
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-11 11:28:31
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/MediaReport/allColumnData.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 维度枚举
export const dimensionEnum = {
  date: {
    name: '日期',
    prop: 'date',
    width: 120,
    value: 'date'
  },
  appName: {
    name: '供应商',
    prop: 'publisherName',
    width: 150,
    value: 'publisher_id'
  },
  placeName: {
    name: '广告位',
    prop: 'placeName',
    width: 150,
    value: 'place_id'
  },
  osName: {
    name: '操作系统',
    prop: 'osName',
    width: 80,
    value: 'os'
  },
  positionName: {
    name: '广告样式',
    prop: 'positionName',
    width: 80,
    value: 'position_id'
  }
}

export const dateColumns = [
  {
    key: dimensionEnum.date.value,
    name: dimensionEnum.date.name,
    checked: false,
    prop: dimensionEnum.date.prop,
    width: dimensionEnum.date.width
  }
]
// 维度column
export const dimensionColumns = [
  {
    key: dimensionEnum.appName.value,
    name: dimensionEnum.appName.name,
    checked: false,
    prop: dimensionEnum.appName.prop,
    width: dimensionEnum.appName.width
  },
  {
    key: dimensionEnum.placeName.value,
    name: dimensionEnum.placeName.name,
    checked: false,
    prop: dimensionEnum.placeName.prop,
    width: dimensionEnum.placeName.width
  },
  {
    key: dimensionEnum.positionName.value,
    name: dimensionEnum.positionName.name,
    checked: false,
    prop: dimensionEnum.positionName.prop,
    width: dimensionEnum.positionName.width
  },
  {
    key: dimensionEnum.osName.value,
    name: dimensionEnum.osName.name,
    checked: false,
    prop: dimensionEnum.osName.prop,
    width: dimensionEnum.osName.width
  }
]

// 指标枚举
export const targetEnum = {
  income: {
    name: '预估收益',
    prop: 'income',
    desc: ``,
    width: 120
  },
  ecpm: {
    name: 'eCPM',
    prop: 'ecpm',
    desc: ``,
    width: 120
  },
  impress: {
    name: '展示',
    prop: 'impress',
    desc: ``,
    width: 120
  },
  impressRate: {
    name: '展示率',
    prop: 'impressRate',
    desc: ``,
    width: 120
  },
  clickRate: {
    name: '点击率',
    prop: 'clickRate',
    desc: ``,
    width: 120
  },
  click: {
    name: '点击',
    prop: 'click',
    desc: ``,
    width: 120
  },
  request: {
    name: '流量请求',
    prop: 'request',
    desc: ``,
    width: 120
  },
  response: {
    name: '流量填充',
    prop: 'response',
    desc: ``,
    width: 120
  }
}

// 指标column
export const targetColumns = [{
    name: targetEnum.income.name,
    prop: targetEnum.income.prop,
    desc: targetEnum.income.desc,
    width: targetEnum.income.width
  },
  {
    name: targetEnum.ecpm.name,
    prop: targetEnum.ecpm.prop,
    desc: targetEnum.ecpm.desc,
    width: targetEnum.ecpm.width
  },
  {
    name: targetEnum.impress.name,
    prop: targetEnum.impress.prop,
    desc: targetEnum.impress.desc,
    width: targetEnum.impress.width
  },
  {
    name: targetEnum.impressRate.name,
    prop: targetEnum.impressRate.prop,
    desc: targetEnum.impressRate.desc,
    width: targetEnum.impressRate.width
  },
  {
    name: targetEnum.clickRate.name,
    prop: targetEnum.clickRate.prop,
    desc: targetEnum.clickRate.desc,
    width: targetEnum.clickRate.width
  },
  {
    name: targetEnum.click.name,
    prop: targetEnum.click.prop,
    desc: targetEnum.click.desc,
    width: targetEnum.click.width
  },
  {
    name: targetEnum.request.name,
    prop: targetEnum.request.prop,
    desc: targetEnum.request.desc,
    width: targetEnum.request.width
  },
  {
    name: targetEnum.response.name,
    prop: targetEnum.response.prop,
    desc: targetEnum.response.desc,
    width: targetEnum.response.width
  }
]
